    <template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Transactions and Payments
            </div>
            <div class="subtitle-1 font-weight-light">
              A list of all Transactions
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:item.created_at="{ item }">{{
                    item.created_at.split("T")[0]
                  }}</template>
                  <template v-slot:item.userType="{ item }">
                    <span class="text-center subtitle-2 text-capitalize">{{
                      item.userType == "citySubscriber"
                        ? "City Subscriber"
                        : item.userType
                    }}</span>
                  </template>
                  <template v-slot:item.totalPrice="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span
                          class="text-center subtitle-2 text-capitalize"
                          v-on="on"
                          >€{{ item.totalPrice }}</span
                        >
                      </template>
                      <span class="d-block">Price: €{{ item.price }}</span>
                      <span class="d-block">VAT: €{{ item.vat }}</span>
                      <span class="d-block"
                        >Total Price: €{{ item.totalPrice }}</span
                      >
                    </v-tooltip>
                  </template>
                  <template v-slot:item.paymentType="{ item }">
                    <span class="text-center subtitle-2 text-capitalize">{{
                      item.paymentType
                    }}</span>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-chip
                      class="ma-2"
                      :color="
                        item.status == 'incomplete'
                          ? 'platinum darken-2'
                          : item.status == 'succeeded'
                          ? 'primary'
                          : 'error'
                      "
                      text-color="white"
                    >
                      <span class="text-center subtitle-2 text-capitalize">{{
                        item.status
                      }}</span>
                      <v-icon right>{{
                        item.status == "incomplete"
                          ? "mdi-clock"
                          : item.status == "succeeded"
                          ? "mdi-check"
                          : "mdi-alert-circle"
                      }}</v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item.invoice="{ item }">
                    <template v-if="item.status == 'succeeded'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            color="grey lighten-1"
                            v-on="on"
                            @click="sendMailFn(item)"
                          >
                            <v-icon>mdi-email</v-icon>
                          </v-btn>
                        </template>
                        <span>Send Invoice to {{ item.email }}</span>
                      </v-tooltip>
                    </template>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransactionsApiCalls from "../../../services/subscribePlane.sevice";
export default {
  name: "Transactions",
  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      headers: [
        {
          sortable: true,
          text: "invoice",
          value: "invoiceId",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Date",
          value: "created_at",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "User Type",
          value: "userType",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Trans. Type",
          value: "paymentType",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Amount",
          value: "totalPrice",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Payment State",
          value: "status",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "",
          value: "invoice",
          class: "primary--text title",
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.isLoading = true;
    TransactionsApiCalls.getAllTransactions().then((res) => {
      // console.log(res.data.content.data);
      this.items = res.data.content.data.map((x) => {
        return {
          ...x,
          isDeleteModal: false,
        };
      });
      this.isLoading = false;
    });
    // .catch(err => {
    //   // console.log(err);
    // });
  },
  methods: {
    sendMailFn(transaction) {
      let payload = {
        _id: transaction._id,
      };
      TransactionsApiCalls.sendMail(payload).then((res) => {
        let result = res.data;
        if (res.status == 200 && result.success) {
          let notifyPayload = {
            snackBarText: `The email has been sent to ${transaction.email}`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", notifyPayload);
        }
      });
    },
  },
};
</script>
